<template>
  <div
    class="modal fade"
    id="modalEditData"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Edit Data</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label>Tanggal Cetak :</label>
                  <Datepicker
                    :autoApply="true"
                    :maxDate="new Date()"
                    :class="{
                      'is-invalid': formError && formError.date_cetak,
                    }"
                    :closeOnScroll="true"
                    v-model="formCetak.date_cetak"
                    placeholder="Tanggal Cetak"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    @cleared="changeDate('', 'date_cetak')"
                    @update:modelValue="changeDate($event, 'date_cetak')"
                  ></Datepicker>
                  <!-- :minDate="formData.tanggal" -->
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label>Tanda Tangan 1 :</label>
                  <Select2
                    v-model="formCetak.signing_person_first"
                    :options="optionEmployee"
                    placeholder="Pilih Karyawan"
                    :class="{
                      'is-invalid': formError && formError.signing_person_first,
                    }"
                    @change="formChange('signing_person_first', $event)"
                    @select="formChange('signing_person_first', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.signing_person_first"
                  >
                    {{ formError.signing_person_first }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label>Tanda Tangan 2 :</label>
                  <Select2
                    v-model="formCetak.signing_person_second"
                    :options="optionEmployee"
                    placeholder="Pilih Karyawan"
                    :class="{
                      'is-invalid':
                        formError && formError.signing_person_second,
                    }"
                    @change="formChange('signing_person_second', $event)"
                    @select="formChange('signing_person_second', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.signing_person_second"
                  >
                    {{ formError.signing_person_second }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label>Tanda Tangan Vendor :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formCetak.signing_person_vendor"
                    :options="optionTandaTanganVendor"
                    placeholder="Pilih Kontak Vendor"
                    class=""
                    :class="{
                      'is-invalid':
                        formError && formError.signing_person_vendor,
                    }"
                    @change="formChange('signing_person_vendor', $event)"
                    @select="formChange('signing_person_vendor', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.signing_person_vendor"
                  >
                    {{ formError.signing_person_vendor }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    rows="3"
                    placeholder="Masukan deskripsi"
                    v-model="formCetak.keterangan"
                    :class="{
                      'is-invalid': formError && formError.keterangan,
                    }"
                    @keyup="formChange('keterangan')"
                    class="form-control"
                  ></textarea>
                  <div
                    class="form-error"
                    v-if="formError && formError.keterangan"
                  >
                    {{ formError.keterangan }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Terms & Condition</label>
                  <textarea
                    type="text"
                    class="form-control"
                    v-model="formCetak.terms_condition"
                    placeholder="Masukkan Terms & Condition"
                    @keyup="formChange('terms_condition', $event)"
                    rows="5"
                    style="height: unset !important"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-add" @click="onSubmit" :disabled="isSubmit">
              <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">Payment Order</h4>
          </div>

          <button
            class="btn-add"
            role="button"
            aria-expanded="false"
            :disabled="!canEdit"
            @click="editData()"
          >
            Edit Data
          </button>
        </div>
        <div class="card-table">
          <iframe id="pdfFrame" v-if="!isLoading"></iframe>
          <div
            v-else
            :style="{
              height: '450px',
              background: '#8f8f8f',
              position: 'relative',
            }"
          >
            <loading
              :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }"
              :active="isLoading"
              :can-cancel="false"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              color="#68caf8"
              height="80"
              width="80"
            ></loading>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "../../../assets/font/poppins-bold.js";
import "../../../assets/font/poppins-regular.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Loading from "vue3-loading-overlay";

import $ from "jquery";
import { get_PaymentOrderCetak, post_PaymentOrderUpdateCetak } from "../../../actions/hutang";
import { checkRules, cksClient, showAlert } from "../../../helper";
import { DATA } from "../../../constans/Url";
import moment from "moment";
import "moment/locale/id";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";
import { get_ListContactVendor } from "../../../actions/vendor";
import { get_KaryawanList } from "../../../actions/karyawan";

export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
    Datepicker,
    Loading,
    Select2,
  },
  data() {
    return {
      showModalRange: false,
      fullPage: true,
      isLoading: true,
      isSubmit: false,
      canvas: null,
      optionDepartement: [],
      dataEstimatedAccount: [],
      optionTandaTanganVendor: [],
      optionEmployee: [],
      kode: this.$route.params.kode,
      dataCompany: {
        nama: "PT. JAWARA KREASITAMA",
        divisi: "162 PRODUCTION",
      },
      dataReport: [],
      dataColumn: [],
      titleDate: "",
      titleDateCol: "",
      dateColumn: {},
      namaReport: "",
      dataCetak: null,
      formCetak: {
        id: '',
        date_cetak: "",
        keterangan: "",
        terms_condition: "",
        signing_person_first: "",
        signing_person_second: "",
        signing_person_vendor: "",
      },
      accessDepartement: cksClient().get("_account").akses_departement,
      formError: [],
      canEdit: false,
      formRules: {
        date_cetak: {
          required: true,
        },
        keterangan: {
          required: true,
        },
        terms_condition: {
          required: true,
        },
        signing_person_first: {
          required: true,
        },
        signing_person_second: {
          required: false,
        },
        signing_person_vendor: {
          required: true,
        },
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    this.getData();
    // this.tooltip();
  },

  methods: {
    editData() {
      $("#modalEditData").modal("show");
    },
    getData() {
      this.isLoading = true;
      get_PaymentOrderCetak(
        {
          kode: this.kode,
        },
        (res) => {
          const { data, terms } = res;
          this.dataCetak = data;
          this.formCetak.date_cetak = moment(data.payment_order.tanggal).format(
            "YYYY-MM-DD"
          );
          this.formCetak.id = this.kode,
          this.formCetak.keterangan = data.payment_order.keterangan;
          this.formCetak.signing_person_first =
            data.payment_order.signing_person_first;
          this.formCetak.signing_person_second =
            data.payment_order.signing_person_second;
          this.formCetak.signing_person_vendor =
            data.payment_order.signing_person_vendor;
          this.formCetak.terms_condition = data.payment_order.terms_condition ?? terms;

          this.getKaryawan(data.payment_order.id_divisi);
          this.getVendorContact(data.payment_order.id_vendor);
          if (data.vendor_contact && data.signing_person_first) {
            this.isLoading = false;
            this.generatePdf(data);
          } else {
            this.canEdit = true;
            showAlert(this.$swal, {
              title: "ERROR!",
              msg: "Silakan edit data terlebih dahulu",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }

        },
        () => {
          showAlert(this.$swal, {
            title: "WARNING!",
            msg: "Terjadi kesalahan silakan ulangi kembali nanti",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataPaymentOrder",
              });
            },
          });
        }
      );
      // this.generatePdf();
    },

    async formChange(key) {
      if (this.formRules[key]) {
        this.formRules[key].changed = true;
      }
      var check = await checkRules(this.formRules, this.formCetak);
      this.formError = check.error;
    },

    getKaryawan(idDivisi) {
      var id_divisi =
        this.accessDepartement && idDivisi ? idDivisi : "";
      get_KaryawanList(
        {
          id_company: cksClient().get("_account").id_company,
          search: "aktif",
          id_divisi: id_divisi,
        },
        async (res) => {
          var { list } = res;
          this.optionEmployee = [];
          this.canEdit = true;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              this.optionEmployee.push({
                id: list[key].id,
                text: list[key].nama,
              });
            }
          }
        }
      );
    },

    getVendorContact(idVendor) {
      get_ListContactVendor(idVendor, (res) => {
        const { list } = res;
        this.optionTandaTanganVendor = [];
        this.canEdit = true;
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionTandaTanganVendor.push({
              id: element.id,
              text: `${element.nama} (${element.jabatan ?? "-"})`,
            });
          }
        }
      });
    },

    async onSubmit() {
      for (const key in this.formRules) {
        if (Object.hasOwnProperty.call(this.formRules, key)) {
          this.formRules[key].changed = true;
        }
      }

      var check = await checkRules(this.formRules, this.formCetak);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    postData() {
      this.isSubmit = true;
      post_PaymentOrderUpdateCetak(
        this.formCetak,
        (res) => {
          this.isSubmit = false;
          $('#modalEditData').modal('hide');
          var msg =
            res.response_code === 201
              ? "Data payment order berhasil dicatat"
              : "Data payment order berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.getData();
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    changeDate(data, key) {
      this.formCetak[key] = moment(data).format("YYYY-MM-DD");
      this.formChangePembayaran(key);
    },

    convertImgToBase64(url) {
      return new Promise((resolve) => {
        var image = new Image();
        image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#6BACCC"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
      });
    },

    splitTextIntoTwoArrays(text) {
      // Step 1: Split the text into words using whitespace as the delimiter
      const wordsArray = text.split(/\s+/);

      // Step 2: Determine the size of each array
      const totalWords = wordsArray.length;
      const arraySize = Math.ceil(totalWords / 2);

      // Step 3: Divide the words into three arrays
      const firstArray = wordsArray.slice(0, arraySize);
      // const secondArray = wordsArray.slice(arraySize, arraySize * 2);
      const secondArray = wordsArray.slice(arraySize);

      return [firstArray, secondArray];
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    async generatePdf(dataCetak) {
      const {
        company,
        payment_order,
        vendor,
        vendor_contact,
        signing_person_first,
        signing_person_second,
      } = dataCetak;
      const doc = new jsPDF("p", "mm", "a4");
      var header = "/img/bg-header.png";
      doc.addImage(header, "png", 220, -35, 220, 2500 / 72, null, null, 180);
      var logo = await this.convertImgToBase64(DATA.COMPANY(company.logo));
      doc.addImage(logo, "png", 5, 8, 17, 17, null, null, 0);

      doc.setFont("Poppins-Bold").setFontSize(13);
      doc.setTextColor(255, 255, 255);
      doc.text(25, 15, `${company.nama}`, {
        align: "left",
      });
      doc.setFont("Poppins-Regular").setFontSize(10);
      doc.setTextColor(255, 255, 255);
      doc.text(25, 22, company.divisi ? company.divisi : company.email, {
        align: "left",
      });

      const [array1, array2] = this.splitTextIntoTwoArrays(company.alamat, 2);

      doc.setFontSize(9);
      doc.setFont("Poppins-Regular");
      doc.splitTextToSize(company.alamat, 10);

      doc.text(205, 12, array1.join(" "), {
        align: "right",
      });
      doc.text(205, 18, array2.join(" "), {
        align: "right",
      });
      doc.text(205, 24, company.no_telp, {
        align: "right",
      });
      doc.text(205, 30, company.divisi ? company.email : "", {
        align: "right",
      });
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      // doc.text(105, 45, this.namaReport, "center");
      doc.setFontSize(9);
      // doc.text(105, 60, this.titleDate, "center");
      // var blankTable = {
      //   columns: [
      //     {
      //       title: "",
      //       dataKey: "id",
      //     },
      //   ],
      //   rows: [
      //     {
      //       id: "",
      //     },
      //   ],
      // };

      // doc.autoTable(blankTable.columns, blankTable.rows, {
      //   margin: {
      //     left: 10,
      //     top: 22,
      //     bottom: 80,
      //   },
      //   theme: "plain",
      // });

      var tableHeder = {
        columns: [
          {
            title: "",
            dataKey: "title_left_header",
          },
          {
            title: "",
            dataKey: "semicolon",
          },
          {
            title: "",
            dataKey: "value_left_header",
          },
          {
            title: "",
            dataKey: "blank_column"
          },
          {
            title: "",
            dataKey: "title_right_header",
          },
          {
            title: "",
            dataKey: "semicolon_",
          },
          {
            title: "",
            dataKey: "value_right_header",
          },
        ],
        rows: [
          {
            title_left_header: "PO NUMBER",
            semicolon: ":",
            value_left_header: `${payment_order.id}`,
            title_right_header: "VENDOR NUMBER",
            semicolon_: ":",
            value_right_header: `${vendor.kode_vendor}`,
          },
          {
            title_left_header: "BILL TO",
            semicolon: ":",
            value_left_header: `${vendor.nama}`,
            title_right_header: "CATEGORY",
            semicolon_: ":",
            value_right_header: `${vendor.category}`,
          },
          {
            title_left_header: "PHONE",
            semicolon: ":",
            value_left_header: `${vendor.no_handphone}`,
            title_right_header: "NPWP",
            semicolon_: ":",
            value_right_header: `${vendor.npwp}`,
          },
          {
            title_left_header: "ADDRESS",
            semicolon: ":",
            value_left_header: `${vendor.alamat}`,
            title_right_header: "BANK ACCOUNT",
            semicolon_: ":",
            value_right_header: `${payment_order.bank} - ${payment_order.nomor_rekening} (${payment_order.atas_nama}) `,
          },
          {
            title_left_header: "ATTENTION",
            semicolon: ":",
            value_left_header: `${vendor_contact.nama}`,
            title_right_header: "P / NP",
            semicolon_: ":",
            value_right_header: `${vendor.npwp ? "P" : "NP"}`,
          },
          {
            title_left_header: "EMAIL",
            semicolon: ":",
            value_left_header: `${vendor_contact.email ?? "-"}`,
            title_right_header: "",
            semicolon_: "",
            value_right_header: "",
          },
          {
            title_left_header: "PROJECT",
            semicolon: ":",
            value_left_header: `${payment_order.nama_project ?? "-"}`,
            title_right_header: "",
            semicolon_: "",
            value_right_header: "",
          },
          {
            title_left_header: "LOCATION",
            semicolon: ":",
            value_left_header: `${payment_order.lokasi_project ?? "-"}`,
            title_right_header: "",
            semicolon_: "",
            value_right_header: "",
          },
        ],
      };

      doc.autoTable(tableHeder.columns, tableHeder.rows, {
        margin: {
          left: 4,
          right: 4,
          top: 38,
          bottom: 80,
        },
        styles: {
          cellPadding: 1,
          fontSize: 9,
        },

        columnStyles: {
          0: {
            cellWidth: 30,
          },
          1: {
            cellWidth: 3,
          },
          2: {
            cellWidth: 70,
          },
          3: {
            cellWidth: 7,
          },
          4: {
            cellWidth: 38,
          },
          5: {
            cellWidth: 3,
          },
          6: {},
        },
        theme: "plain",
      });

      var tableData = {
        columns: [
          {
            title: "NO",
            dataKey: "no",
          },

          {
            title: "DESCRIPTION",
            dataKey: "description",
          },

          {
            title: "%",
            dataKey: "percent",
          },
          {
            title: "UNIT PRICE",
            dataKey: "unit_price",
          },
          {
            title: "TOTAL",
            dataKey: "total",
          },
        ],
        rows: [
          {
            no: "1",
            description: payment_order.keterangan,
            percent: "100%",
            unit_price: `Rp${this.formatMoney(payment_order.nilai)}`,
            total: `Rp${this.formatMoney(payment_order.nilai)}`,
          },

          {
            no: "",
            description: `PPH PASAL 23 - NPWP (${
              vendor.npwp ? payment_order.potongan_hutang : "2"
            }%)`,
            percent: "",
            unit_price: "",
            total: `Rp${
              vendor.npwp ? this.formatMoney(payment_order.potongan) : "0"
            }`,
          },
          {
            no: "",
            description: `PPH PASAL 23 - TIDAK BER NPWP (${
              !vendor.npwp ? payment_order.potongan_hutang : "4"
            }%)`,
            percent: "",
            unit_price: "",
            total: `Rp${
              !vendor.npwp ? this.formatMoney(payment_order.potongan) : "0"
            }`,
            is_border: 1,
          },
        ],
      };
      doc.autoTable(tableData.columns, tableData.rows, {
        didParseCell: function (data) {
          if (data.row.section === "head") {
            data.cell.styles.textColor = "#fff";
            if (data.column.index == 3 || data.column.index == 4) {
              data.cell.styles.halign = "right";
            }
          }
          if (data.column.index === 0 && data.row.raw.padding) {
            var padding = parseInt(data.row.raw.padding)
              ? parseInt(data.row.raw.padding)
              : 0;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
              top: 2,
            };
          }

          // console.log(tableData.rows);
        },

        didDrawCell: function (data) {
          if (data.row.raw.is_border && data.row.raw.is_border == 1) {
            var xPos = data.cell.x;
            var yPos = data.cell.y + data.row.height + 5;
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.2); // Border width
            doc.line(xPos, yPos, xPos + data.cell.width, yPos);
          }

          // console.log(tableData.rows);
        },

        columnStyles: {
          0: {},
          1: {},
          2: {},
          3: {
            halign: "right",
          },
          4: {
            halign: "right",
          }
        },
        styles: {
          cellPadding: 2,
          fontSize: 8,
        },

        headStyles: {
          fillColor: [107, 172, 204],
        },
        margin: {
          left: 4,
          right: 4,
          top: 0,
        },
        theme: "plain",
      });

      var tableTotal = {
        columns: [
          {
            title: "",
            dataKey: "title_total",
          },
          {
            title: "",
            dataKey: "blank",
          },
          {
            title: "",
            dataKey: "value_total",
          },
        ],
        rows: [
          {
            title_total: "GRAND TOTAL :",
            blank: "",
            value_total: `Rp${this.formatMoney(
              payment_order.nilai - payment_order.potongan
            )}`,
          },
          {
            title_total: "REMAINING PAYMENT :",
            blank: "",
            value_total: `Rp${this.formatMoney(
              payment_order.nilai -
                payment_order.potongan -
                payment_order.pembayaran
            )}`,
          },
        ],
      };
      doc.autoTable(tableTotal.columns, tableTotal.rows, {
        didParseCell: function (data) {
          if (data.row.index == 0) {
            data.cell.styles.fontSize = 10;
          }
          if (data.column.index === 0 && data.row.raw.padding) {
            var padding = parseInt(data.row.raw.padding)
              ? parseInt(data.row.raw.padding)
              : 0;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
              top: 2,
            };
          }
        },

        columnStyles: {
          0: {
            halign: "right",
            cellWidth: 159,
          },
          1: {
            cellWidth: 12,
          },
          2: {
            halign: "right",
            cellWidth: 30,
          },
        },
        styles: {
          cellPadding: 2,
          fontSize: 8,
        },

        margin: {
          left: 4,
          right: 4,
          top: 0,
        },
        theme: "plain",
      });

      doc.lineHeightProportion = 2;
      var tableReturn = {
        columns: [
          {
            title: "",
            dataKey: "text_return",
          },
        ],
        rows: [
          {
            text_return: payment_order.terms_condition.replaceAll("\n", "\n\n"),
          },
        ],
      };
      var pageWidth = doc.internal.pageSize.getWidth();
      doc.autoTable(tableReturn.columns, tableReturn.rows, {
        tableWidth: pageWidth - (pageWidth * 25) / 100,
        styles: {
          cellPadding: 0,
          fontSize: 7,
          cellHeight: 1,
        },

        margin: {
          left: 10,
          right: 10,
          top: 10,
        },
        theme: "plain",
      });

      var titleTTD = {
        columns: [
          {
            title: "",
            dataKey: "tanggal",
          },

          {
            title: "",
            dataKey: "lineup",
          },
        ],
        rows: [
          {
            tanggal: `Yogyakarta, ${moment(payment_order.tanggal).format(
              "DD MMMM YYYY"
            )}`,
            lineup: vendor.nama,
          },
        ],
      };
      doc.autoTable(titleTTD.columns, titleTTD.rows, {
        columnStyles: {
          0: {
            halign: "left",
            cellWidth: 146,
          },
          1: {
            halign: "center",
            cellWidth: 30,
          },
        },
        styles: {
          cellPadding: 3,
          fontSize: 8,
        },

        margin: {
          top: 50,
        },
        theme: "plain",
      });

      var blankTable1 = {
        columns: [
          {
            title: "",
            dataKey: "id",
          },
        ],
        rows: [
          {
            id: "",
          },
        ],
      };
      doc.autoTable(blankTable1.columns, blankTable1.rows, {
        margin: {
          bottom: 10,
        },
        theme: "plain",
      });

      var tableTTD = {
        columns: [
          {
            title: "",
            dataKey: "nama_1",
          },

          {
            title: "",
            dataKey: "nama_2",
          },
          {
            title: "",
            dataKey: "blank",
          },

          {
            title: "",
            dataKey: "nama_vendor",
          },
        ],
        rows: [
          {
            is_border: 1,
            nama_1: `${signing_person_first.nama} \n ${signing_person_first.jabatan} 1 \n ${signing_person_first.id_karyawan}`,
            nama_2: signing_person_second
              ? `${signing_person_second.nama} \n  ${signing_person_second.jabatan} 1 \n ${signing_person_second.id_karyawan}`
              : "",
            blank: "",
            nama_vendor: `${vendor_contact.nama} \n Signature & Company Stamp`,
          },
        ],
      };
      doc.autoTable(tableTTD.columns, tableTTD.rows, {
        didParseCell: function (data) {
          if (data.column.index === 2 && data.column.index === 1) {
            data.cell.styles.cellPadding = {
              left: 10,
            };
          }
        },

        didDrawCell: function (data) {
          var xPos = data.cell.x;
          var yPos = data.cell.y;
          if (
            data.row.raw.is_border &&
            data.row.raw.is_border == 1 &&
            data.column.index == 0
          ) {
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.2); // Border width
            doc.line(xPos, yPos, xPos + data.cell.width, yPos);
          }
          if (
            data.row.raw.is_border &&
            data.row.raw.is_border == 1 &&
            data.column.index == 1 &&
            signing_person_second
          ) {
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.2); // Border width
            doc.line(xPos + 5, yPos, xPos + data.cell.width - 5, yPos);
          }

          if (
            data.row.raw.is_border &&
            data.row.raw.is_border == 1 &&
            data.column.index == 3
          ) {
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.2); // Border width
            doc.line(xPos, yPos, xPos + data.cell.width, yPos);
          }
        },
        tableWidth: "100%",

        columnStyles: {
          0: {
            cellWidth: 40,
          },

          1: {
            cellWidth: 45,
          },

          2: {
            cellWidth: 60,
          },
          3: {
            halign: "center",
          },
        },
        styles: {
          halign: "center",
          fontSize: 8,
          cellPadding: 2,
        },

        margin: {
          top: 200,
        },
        theme: "plain",
      });

      setTimeout(() => {
        var iframe = document.getElementById("pdfFrame");
        iframe.src = doc.output("bloburi", {
          filename: `Neraca .pdf`,
        });
      }, 500);
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
  },
};
</script>

<style scoped>
.card-table {
  margin-top: 0px !important;
}

.btn-gaji {
  background-color: #68caf8;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-bonus {
  background-color: #f8ce3a;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-thr {
  background-color: #ff5252;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.dropdown-menu {
  background: transparent !important;
  box-shadow: none !important;
  width: 102px !important;
  min-width: unset;
}

.dropdown-item:nth-child(2) {
  margin-top: 6px;
}

.dropdown-item:nth-child(3) {
  margin-top: 6px;
}

iframe {
  z-index: 1000;
  width: 100%;
  height: 800px;
}
</style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
